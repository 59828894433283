@import url("@elilillyco/ux-lds/src/css/components/_index.css");
@import url('@elilillyco/ux-lds/src/css/themes/v1/_index.css');

@import url('@elilillyco/ux-lds-react/src/css/components/_index.css');


input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search-submitbg-color button[type="submit"] {
    background-color : var(--lds-color-primary);
}

.search-submitbg-color button[type="submit"] svg {
    fill : var(--lds-color-white);
}

.search-submitbg-color span {
    color: #0078a3;
    text-decoration: underline;
    font-weight: normal;
    
}

.search-submitbg-color button {
    overflow: hidden;
}
