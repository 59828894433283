@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;700&display=swap");
@import "./core.css";

/* @import 'bootstrap/dist/css/bootstrap.min.css'; */

#stickyHeader {
  position: fixed;
  height: 90px;
  z-index: 1000;
}

div h3 {
  margin-bottom: 0;
}

#mainheading {
  font-weight: bolder;
  padding-left: 5px;
}

#header-id {
  height: 90px;
}

.container {
  padding-right: 90px;
  padding-left: 90px;
  margin-top: 90px;
}

/* pagination */
body {
  color: #212121;
}
.zindex2 {
  z-index: 2;
}
.text-muted {
  color: #757575 !important;
}
.mt0 {
  margin-top: 0px;
}
.mt8 {
  margin-top: 8px;
}
.mt10 {
  margin-top: 10px;
}
.mt12 {
  margin-top: 12px;
}
.mt16 {
  margin-top: 16px;
}
.mt24 {
  margin-top: 24px;
}
.mt28 {
  margin-top: 28px;
}
.mt32 {
  margin-top: 32px;
}
.mt40 {
  margin-top: 40px;
}
.mt48 {
  margin-top: 48px;
}
.mb0 {
  margin-bottom: 0px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb28 {
  margin-bottom: 28px;
}
.mb32 {
  margin-bottom: 32px;
}
.mb40 {
  margin-bottom: 40px;
}
.mr8 {
  margin-right: 8px;
}
.mb3 {
  margin-bottom: 3px;
}
.mr12 {
  margin-right: 12px;
}
.mr16 {
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}
.mr24 {
  margin-right: 24px;
}
.mr28 {
  margin-right: 28px;
}
.mr32 {
  margin-right: 32px;
}
.mr40 {
  margin-right: 40px;
}
.mr48 {
  margin-right: 48px;
}
.p0 {
  padding: 0px;
}
.pt8 {
  padding-top: 8px;
}
.pt16 {
  padding-top: 16px;
}
.pt24 {
  padding-top: 24px;
}
.pt28 {
  padding-top: 28px;
}
.pt32 {
  padding-top: 32px;
}
.pt40 {
  padding-top: 40px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb16 {
  padding-bottom: 16px;
}
.pb24 {
  padding-bottom: 24px;
}
.pb32 {
  padding-bottom: 32px;
}
.pb40 {
  padding-bottom: 40px;
}
.pb36 {
  padding-bottom: 36px;
}
.pb37 {
  padding-bottom: 37px;
}
.pl0 {
  padding-left: 0px;
}
.pl8 {
  padding-left: 8px;
}
.pl16 {
  padding-left: 16px;
}
.pl24 {
  padding-left: 24px;
}
.pl32 {
  padding-left: 32px;
}
.pl40 {
  padding-left: 40px;
}
.pl48 {
  padding-left: 48px;
}
.pl36 {
  padding-left: 36px;
}
.pl37 {
  padding-left: 37px;
}
.pl0 {
  padding-left: 0px !important;
}
.ml8 {
  margin-left: 8px;
}
.ml12 {
  margin-left: 12px;
}
.ml16 {
  margin-left: 16px;
}
.ml24 {
  margin-left: 24px;
}
.ml32 {
  margin-left: 32px;
}
.ml40 {
  margin-left: 40px;
}
.ml48 {
  margin-left: 48px;
}
.ml36 {
  margin-left: 36px;
}
.ml37 {
  margin-left: 37px;
}
.ml40 {
  margin-left: 40px;
}
.ml20 {
  margin-left: 20px;
}
.ml10 {
  margin-left: 10px;
}
.mt120 {
  margin-top: 120px;
}
.font14 {
  font-size: 14px;
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.font24 {
  font-size: 24px;
}
.disbabled-datepicker {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding-left: 16px;
  color: #757575;
}
#pageid {
  margin: 10px;
  border: 0px;
}

.page-item {
  background-color: transparent;
}
u {
  color: #0078a3 !important;
}
.pagination li p {
  color: #0078a3;
}

.pagination li button {
  border: 2px solid #0078a3;
  color: #0078a3;
  width: 120px;
  height: 35px;
  border-radius: 4px;
  -left: 300px;
  margin-top: 18px;
}
.pagination li {
  margin-top: 24px;
}
ul.pagination .page-item.active .page-link.active {
  background-color: #0078a3 !important;
  padding: 0.5rem 0.85rem !important;
}
.page-link {
  background-color: transparent !important;
  padding: 0.5rem 0.85rem !important;
}
.prevnext {
  margin-top: 15px;
  margin-left: 24px;
  margin-right: 24px;
  cursor: pointer;
}
.disablednav {
  color: #616161 !important;
  cursor: default;
}
.headersort {
  color: #0078a3 !important;
  cursor: pointer;
}
.alignprd {
  text-align: right !important;
  width: 104px !important;

  padding-right: 20px !important;
}
.alignprd input {
  border-radius: 5px;
  text-align: center;
  width: 60px !important;
}
.prdcolor {
  background-color: #fbd7a3 !important;
}
.prdcolor2 {
  background-color: #f9b7bf !important;
}
.prdcolor3 {
  background-color: #b5e3cc !important;
}
.hd_textclr {
  color: #616161 !important ;
}

/* pagination */
.refreshCommonBtn {
  width: 199px;
  height: 40px;
  font-size: 18px;
}
.w163h40 {
  width: 163px;
  height: 40px;
  font-size: 18px;
}
.loginbtn {
  width: 127px;
  height: 40px;
}

/*complaint form*/
.header {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000000;
  text-align: center;
}
.headertop {
  width: 100%;
  position: fixed;
  margin: 0 auto;
  z-index: 37;
  background: none;
  background-color: #eeeeee;
}
.headertop-title {
  width: 30%;
  height: 105px;
  border: none;
  background: #fafafa;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-size: 24px;
  line-height: 26px;
  font-weight: normal;
  margin: 0 auto 0 35%;
  display: inline-block;
  text-align: center;
}
.header-links {
  width: 15%;
  margin-top: 2%;
  margin-left: auto;
  float: right;
  text-align: right;
}
.draft_area {
  position: relative;

  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 55%;
  border: 1px solid #bdbdbd;

  background-color: #f5f5f5;
}
.draft_area span {
  vertical-align: bottom;
  padding: 20px;
  border: none;
  width: auto;
  font-weight: bold;

  font-size: 28px;
}
.noborder-right {
  border-right: none !important;
}
.noborder-left {
  border-left: none !important;
}
.rightbutton {
  display: flex;
}
.rightbutton a {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.inputClass {
  width: 200px;
  height: 40px;
  padding: 0 1%;
}
.longInputClass {
  width: 40%;
  height: 40px;
}
.recoder p {
  width: 100%;
  float: left;
  padding-right: 12px;
}
.section-title {
  background-color: #d52b1e;
  color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  display: block;
  width: 100%;
}
.form-label {
  vertical-align: middle;
  line-height: 1.5em;
  padding-top: 5px;
  padding-bottom: 5px;
}
.box-border {
  padding: 1%;
  margin-bottom: 1%;
  border: 1px solid #bdbdbd;
  background-color: #f5f5f5;
}
a.lds-link-header {
  font-size: inherit;
  font-weight: 500;
  color: #0078a3;
  line-height: 2.5rem;
}
a.lds-link-header:hover {
  text-decoration: underline;
}
.lds-header-logo-wrapper {
  justify-content: flex-start;
  width: auto;
  min-height: 4.5rem;
}
.lds-header {
  position: relative;
  z-index: 10;
  width: 100%;
  background: #fff;
}
.lds-sticky-container {
  position: relative;
  top: 0;
  width: 100%;
}
.lds-link.lds-logo-lilly.logo-md {
  width: 88.04651px;
  height: 48px;
  display: block;
  padding: 24px 23.97674px;
}
.lds-header-main {
  z-index: 5;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #fff;
}
.ldc-sticky-header .lds-sticky-container {
  box-shadow: 0 1px 3px rgb(51 51 51 / 20%);
}
.lds-link {
  font-weight: 400;
  font-family: Noto Sans JP, Arial, sans-serif;
}
.lds-link,
.lds-link:focus,
.lds-link:hover {
  text-decoration: underline;
}
.wrapper {
  width: 1216px;
  margin: 0 auto;
}
h1 {
  padding: 0.5rem !important;
  font: 400 2rem/3rem Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
}
h3 {
  margin-bottom: 1.5rem;
  font: 700 20px / 2.5rem Roboto, "Noto Sans JP", "�q���M�m�p�S ProN W3",
    "Hiragino Kaku Gothic ProN", ���S�V�b�N, YuGothic, ���C���I, Meiryo,
    sans-serif;
}

/* styles.css */
.modal-block p.thankyou {
  margin-bottom: 20px;
  margin-top: -10%;
}

.thankyou h4 {
  font-size: 1.4rem;
  font-weight: bold;
  color: #000;
}

.bg-black {
  color: #000;
  font-weight: unset;
}

.bg-bold-black {
  font-weight: bold;
  color: #000;
}

.custom-modal {
  font-family: "Roboto", "Noto Sans JP", "ヒラギノ角ゴ ProN W3",
    "Hiragino Kaku Gothic ProN", sans-serif;
}

.modal-header {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-image {
  width: 100px;
  margin-bottom: 10px;
}

.modal-body {
  text-align: center;
}

.modal-body {
  width: 80%;
}

.modal-body {
  margin-left: 60px;
  margin-right: 0px;
}

.modal-footer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0 0;
}

.no-button {
  background-color: white;
  color: #d52b1e;
  border: 2px solid #d52b1e;
  margin-bottom: 10px;
}

.yestt-button {
  background-color: #d52b1e;
  color: white;
  border: 2px solid #d52b1e;
}

.modal-content {
  width: 600px;
  max-width: 74%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}

.modal-header {
  padding: 0px;
  margin-bottom: 0;
}

.modal-body {
  padding: 0px;
  margin-bottom: 0;
}

.modal-body {
  position: relative;
  padding: 0px 0px 0px 0px !important;
  margin-left: 3%;
}
.modal-footer {
  padding: 15px 15px 0px !important;
  margin-top: -45px;
}

.button-container {
  text-align: center;
}

.button-container .btn {
  display: inline-block;
  margin: 5px;
}

.custom-modal {
  background-color: rgba(33, 33, 33, 0.75);
  backdrop-filter: blur(10px);
}

.parent{
  align-items: center;
  }

@media (max-width: 767.98px) {
  .order-lg-1-sm {
    order: 2 !important;
  }
  .order-lg-2-sm {
    order: 1 !important;
  }
}

@media (min-width: 768px) {
  .order-lg-1-sm {
    order: 1 !important;
  }
  .order-lg-2-sm {
    order: 2 !important;
  }
}

h6 {
  margin-bottom: -0.5rem;
    font-weight: bold;
    margin-top: 0.5rem;
}