.lds-footer {
    padding: 20px 0 56px;
    text-align: center;
    background: #fafafa;
    border-top: 2px solid #bdbdbd;
}
.mt-7{
    margin-top:70px;
}
.lds-footer-meta {
    margin-bottom: 24px;
}
.lds-footer-meta p{
color:#000;
vertical-align: inherit;
font-size:12px;
}
.lds-footer-global-links a {
    display: inline-block;
    margin-left: 32px;
    font-size: 13px;
}
.lds-footer-main-links{
    padding-bottom: 16px;
}
.lds-footer-main-links a {
    display: inline-block;
    margin-bottom: 16px;
}
.lds-link, .lds-link:focus, .lds-link:hover {
    text-decoration: underline;
}
.footWrap{
    padding:0px 20px;
}
.lds-link {
    font-size: inherit;

    color: #0078a3;
    font-weight: 400;
    font-family: Noto Sans JP,Arial,sans-serif;
}
.lds-link.lds-logo-lilly.logo-md {
    width: 88.04651px;
    height: 78px;
    padding: 24px 23.97674px;
}