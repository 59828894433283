
/* .bgtaolor{
    background-color: rgba(107,216,255,0.1);
} */
body {
    font-family: 'Noto Sans JP';
}
a{
    color: #0078a3;
    text-decoration: underline;
}
a:hover{
    color: 0078a3;
    
}
.modal-header{
    border-bottom: none !important;
    padding-bottom: 0px !important;
}
.modal-footer{
    border-top: none !important;
    padding: 48px 0px 0px 0px !important;
}
.close{
    color: #D52B1E;
    opacity: 1 !important;
}
.thumb-horizontal {
  border-radius: 8px;
  background: #C1C1C1;
  z-index: 4;
}
table > thead > tr > th {
  border-bottom: 2px solid #D52B1E !important;
}
.tableHorizontal::-webkit-scrollbar {
  height: 5px;              /* height of horizontal scrollbar ← You're missing this */
  /* width: 400px;               width of vertical scrollbar */
  border: 1px solid #d5d5d5;
}
.tableHorizontal::-webkit-scrollbar-track {
  border-radius: 0;
  background: #F8F8F8;
}

.tableHorizontal::-webkit-scrollbar-thumb {
  height: 7px;
  width: 400px;
  border-radius: 20px;
  background: #C1C1C1;
}
.fixedHeader {

  /* Stick to the left */
  position: sticky;
  left: 0;
  border-left: 1px solid #e0e0e0 !important;
  /* Displayed on top of other rows when scrolling */
  z-index: 3;
}
.fixedHeader2 {

  /* Stick to the left */
  position: sticky;
  left: 128px;
  border-left: 1px solid #e0e0e0 !important;
  /* Displayed on top of other rows when scrolling */
  z-index: 3;
}
.fixedHeader3 {

  /* Stick to the left */
  position: sticky;
  border-left: 1px solid #e0e0e0 !important;
  left: 392px;
  /* Displayed on top of other rows when scrolling */
  z-index: 3;
}
.modalBtn{
    width: 109px;
    height: 40px;
}
.modalHeadText{
    font-size: 24px;
}
.modalBodyText{
    font-size: 16px;
}
.modal-body{
    padding: 0px 48px 2rem 48px;
}
.modal-dialog{
    width: 688px !important;
    height: 272px;
}
@media (min-width: 576px){
    .modal-dialog{
        max-width: 688px !important;
        height: 272px;
    }
}
.modal-backdrop
{
    opacity:0.7 !important;
}
.tableWrapper{
    overflow: auto;
    width: 100%;
    box-shadow: 0 3px 3px rgba(0,0,0,0.2)!important;
}
.tableWrapperlr{
  overflow: auto;
  width: 100%;
  
}
.tableWrapperWithoutOverflow{
  width: 100%;
  box-shadow: 0 3px 3px rgba(0,0,0,0.2)!important;
}
.tablelr{
  box-shadow: 0 3px 3px rgba(0,0,0,0.2)!important;
}
.text-right{
  text-align: right;
}
.text-left{
  text-align: left;
}
.text-center{
  text-align: center;
}

.tablepad16 td {
border: 1px solid #e0e0e0;
text-align: left;
padding: 16px;
white-space: nowrap;

}
.tablepad16 th {
border: 1px solid #e0e0e0;
text-align: center;

padding: 16px;
white-space: nowrap;
}  
.tablepad8 td {
border: 1px solid #e0e0e0;
text-align: left;
padding: 8px;
white-space: nowrap;

}
.tablepad8 th {
border: 1px solid #e0e0e0;
text-align: center;

padding: 8px;
white-space: nowrap;
}  
.tableadecheck td {
  border: 1px solid #e0e0e0;
  text-align: left;
  padding: 16px;
  word-wrap: break-word  ;
  background-color: #ffffff;
  }
.tableadecheck th {
  border: 1px solid #e0e0e0;
  text-align: center;
  background-color: #ffffff;
  word-wrap: break-word !important ;
  padding: 16px;
}  
.btnWhite{
  background-color: #ffffff;
  border: 2px solid !important;
}
.lds-text-field-label{
  font: 400 .875rem/1.5rem Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #212121;
}
 .lds-text-field-input.error {
  background-color: rgba(239,74,95,.1);
  border-color: #ef4a5f;
  color: #db144f;
}
 .lds-text-field-input {
  display: block;
  width: 100%;
  height: 2.5rem;
  padding: calc(.5rem - 1px) calc(1rem - 1px);
  font-size: 100%;
  line-height: 2;
  border: 1px solid #9e9e9e;
  border-radius: 3px;}
  .lds-text-field-input.valid {
    border-color: #3da470;
}
.lds-text-field{
  /* display: block; */
  height: 2.5rem;
  padding: 7px 15px;
  font-size: 16px;
  line-height: 2;
  border: 1px solid #9e9e9e;
  border-radius: 3px;
}
.lds-text-field:focus{
    border-color: #212121;
    outline: 0;
    -webkit-box-shadow: 0 0 2px 2px rgb(33 33 33 / 15%);
    box-shadow: 0 0 2px 2px rgb(33 33 33 / 15%);
}
/*drop down css here*/
.lds-button-base {
  padding: 0;
  margin: 0;
  font-family: Roboto,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.lds-select-field-selected button {
  position: relative;
  display: block;
  width: 100%;
  min-height: 2.5rem;
  padding: calc(.5rem - 1px) calc(2.25rem - 1px) calc(.5rem - 1px) calc(1rem - 1px);
  font-size: 100%;
  line-height: 1.5rem;
  text-align: left;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
}
 .lds-select-field-selected .dropdown-icon {
  position: absolute;
  top: calc(.875rem - 1px);
  right: calc(1rem - 1px);
  width: .75rem;
  height: .75rem;
  -webkit-transition: -webkit-transform .5s ease;
  transition: -webkit-transform .5s ease;
  transition: transform .5s ease;
  transition: transform .5s ease,-webkit-transform .5s ease;
 }
.lds-select-field-dropdown .lds-select-field-option:active {
  background-color: rgba(0,0,0,.16);
}
.lds-select-field-dropdown .lds-select-field-option:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
 .lds-select-field-dropdown .lds-select-field-option {
  display: block;
  width: 100%;
  min-height: 2.5rem;
  padding: .5rem 1rem;
  text-align: left;
  -webkit-box-shadow: inset 0 0 0 2px transparent;
  box-shadow: inset 0 0 0 2px transparent;
  -webkit-transition: background-color .35s ease;
  transition: background-color .35s ease;
}
.lds-button-danger{
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    border: 2px solid transparent;
    background-color: #d52b1e;
    border-radius: 5px;
    color: #f5f5f5;
  }
.lds-button-danger:hover {
    background-color: #af261b;
    color: #f5f5f5;
}
.lds-button-primary{
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
    
    background-color: #0078a3;
    border : 2px solid transparent;
    border-radius: 5px;
    color: #ffffff !important;
}
.lds-button-primary:hover {
  background-color: #015979;
  color: #ffffff;
  
}
.lds-button-secondary{
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #0078a3;
    background-color: white;
    border-color: #0078a3;
    border:2px solid;
    box-shadow: 0 1px 3px rgb(0 0 0 / 20%);
  }
.lds-button-secondary:hover {
    color: #015979;
    border-color: #015979;
    background-color: #e6f2f6;
}
.lds-button-secondary-disabled{
    padding-right: 10px;
    padding-left: 10px;
    font-size: 16px;
    line-height: 16px;
    color: #bdbdbd;
    background-color: #f5f5f5;
    border-color: #bdbdbd !important;
    border: 2px solid;
    cursor: not-allowed !important;
}
.lds-button-secondary-disabled:hover, .lds-button-secondary-disabled:focus{
  padding-right: 10px;
  padding-left: 10px;
  font-size: 18px;
  line-height: 16px;
  color: #bdbdbd;
  background-color: #f5f5f5;
  border-color: #bdbdbd !important;
  border: 2px solid;
  cursor: not-allowed !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;   
}
.lds-select-field{
    height: 40px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 24px;
    border: 1px solid #9e9e9e;
    border-radius: 3px;
    padding: 8px 36px 8px 16px !important;
}
.lds-select-field:focus{
    border-color: #212121;
    outline: 0;
    -webkit-box-shadow: 0 0 2px 2px rgb(33 33 33 / 15%);
    box-shadow: 0 0 2px 2px rgb(33 33 33 / 15%);
}
.lds-select-field-disabled{
    height: 40px;
    padding: 7px 15px;
    font-size: 16px;
    line-height: 24px;
    background-color: #f5f5f5;
    border: 1px solid #9e9e9e;
    border-radius: 3px;
    padding: 8px 36px 8px 16px !important;
}
.lds-checkbox{ 
    position: absolute;
    width: 1px;
    height: 1px;
    margin-left: -1px;
    opacity: 0;
}
.checkcolor.animation:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    width: 250%;
    padding-bottom: 250%;
    margin-top: -125%;
    margin-left: -125%;
    pointer-events: none;
    background: rgba(0,0,0,0);
    border-radius: 1000px;
    content: "";
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .5s cubic-bezier(.215,.61,.355,1);
    transition: all .5s cubic-bezier(.215,.61,.355,1);
}
.width-100{
    width: 100%;
}
.width-250{
  width:250px;
}
.noRightShadow{
  box-shadow: 0 3px 0px rgba(0,0,0,0.2)!important;
}
.noDisplay{
  display: none;
}
.tableOuterDiv1 {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 60px;
  padding-top:0px;
}
.tableOuterDiv {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 60px;
    padding-top:32px;
  }
  .tableInnerDiv td {
  border: 1px solid #e0e0e0;
  text-align: left;
  padding: 25px;
      white-space: nowrap;
      background-color: #ffffff;
  }
  .tableInnerDiv th {
      border: 1px solid #e0e0e0;
      text-align: center;
      background-color: #ffffff;
  
      padding: 25px;
      white-space: nowrap;
      }
      .tableInnerDivAppList td {
        border: 1px solid #e0e0e0;
        text-align: left;
        padding-left: 8px;
        padding-right: 8px;
        padding-bottom: 16px;
        padding-top: 16px;
            white-space: nowrap;
            background-color: #ffffff;
        }
        .tableInnerDivAppList th {
            border: 1px solid #e0e0e0;
            text-align: center;
            background-color: #ffffff;
        
            padding-left: 8px;
            padding-right: 8px;
            padding-bottom: 16px;
            padding-top: 16px;
            white-space: nowrap;
            }
      #pageid {
          border-radius: 100px;
          margin: 10px;
          
      }
      
      .page-item{
          background-color: transparent;
          cursor: pointer;
      }
      u{
          color:#0078a3 !important;
      }
  .pagination li p
  {
      color: #0078a3;
  }
  
      .pagination li button{
          border: 2px solid #0078a3;
          color: #0078a3;
          width: 120px;
          height: 35px;
          border-radius: 4px;
          -left: 300px;
          margin-top: 18px;
      }
      .pagination li {
          margin-top: 25px;
      }
       /* .active {
        background-color: #0078a3 !important;
    } */

      .calicon {
        background: url('../images/calendar.svg') no-repeat;
        border:1px solid #ccc;
        height:20px;
        width:100%;
        padding-left:20px;
        background-size: 20px;
         background-position: 95%;
         border-radius: 5px;
         background-color: #ffffff;
      }

      .card-header{
        background-color: transparent !important;
        border: none !important;
        padding: 0px !important;
    }
    .card {
        background-color: transparent !important;
        border: none !important;
    }
    .card-body{
        background-color: white !important;
        padding-left: 16px !important;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        border-radius: 4px;
    }
    .lds-figure-caption{
        margin-bottom: .5rem;
        color: #0078a3;
        background-color: #ffffff;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none !important;
        padding-top: 6px !important;
        padding-bottom: 10px !important;
        padding-right: 8px !important;
        border-radius: 6px;
        font-weight: 500;
    }
    .lds-figure-caption:hover{
        margin-bottom: .5rem;
        color: #0078a3;
        background-color: #e0e0e0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none !important;
        padding-top: 6px !important;
        padding-bottom: 10px !important;
        padding-right: 8px !important;
        border-radius: 6px;
        font-weight: 500;
    }
    .lds-red-text{
        color: #D52B1E;
    }
    .lds-blue-text{
      color: #0078A3;
    }
    select {
        -webkit-appearance: none;
        appearance: none;
      }
      .select-wrapper {
        position: relative;
      }
      
      .select-wrapper::after {
        /* content: url(../images/CaretDown.svg);  */
        content: url(../images/CaretDown.svg);
    font-size: 0.75rem;
    top: -2px;
    right: 25px;
    position: relative;
    pointer-events: none;
      }  
      .select-wrapper-2 {
        position: relative;
      }
      
      .select-wrapper-2::after {
        content: url(../images/CaretDownGrey.svg); 
    font-size: 0.75rem;
    top: -2px;
    right: 60px;
    position: relative;
    pointer-events: none;
      } 
      .select-wrapper-3 {
        position: relative;
      }
      
      .select-wrapper-3::after {
        content: url(../images/CaretDown.svg); 
    font-size: 0.75rem;
    top: -2px;
    right: 30px;
    position: relative;
    pointer-events: none;
      } 
      .select-wrapper-4 {
        position: relative;
      }
      
      .select-wrapper-4::after {
        content: url(../images/CaretDown.svg); 
    font-size: 0.5rem;
    top: -4px;
    right: 23px;
    position: relative;
    pointer-events: none;
      } 
      .select-wrapper-5 {
        position: relative;
      }
      
      .select-wrapper-5::after {
        content: url(../images/CaretDown.svg); 
    font-size: 0.5rem;
    top: -4px;
    right: 32px;
    position: relative;
    pointer-events: none;
      } 
      .grey{
          color: #ccc;
      } 
      .bgchange {
        background-color: rgba(107,216,255,0.2) !important;
    }
    .navpadding {
        padding-left: 75px;
        padding-right:75px;
    }
    .flexbox {
        font-size: 14px;
    }
    .lds-table-shadow{
        box-shadow: 0 3px 3px rgba(0,0,0,0.2)!important;
    }


    .customradio {
        display: block;
        position: relative;
        
        cursor: pointer;
        font-size: 18px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        
      }
      
      .customradio input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }
      
      .radiomark {
        position: absolute;
        margin-top:12px;
        height: 16px;
        width: 16px;
        background-color: white;
        border-radius: 50%;
        border :2px solid black;
      }
      
      /* .customradio input:checked ~ .radiomark {
        background-color: white;
        border :2px solid #3DA470;
      }
      .customradio radio input:checked ~ .radiomark {
        background-color: #3DA470;
        
      }
       */
      .radiomark:after {
        content: "";
        position: absolute;
        display: none;
        background-color:#121212;
      }
      
      .customradio input:checked ~ .radiomark:after {
        display: block;
      }
      
      /* .customradio .radiomark:after {
           top: 2px;
          left: 2px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background:#3DA470 ;
         
      } */
      /*trial*/
      @keyframes click-wave {
        0% {
          height: 40px;
          width: 40px;
          opacity: 0.35;
          position: relative;
        }
        100% {
          height: 200px;
          width: 200px;
          margin-left: -80px;
          margin-top: -80px;
          opacity: 0;
        }
      }
      
      .option-input {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        position: relative;
        top: 13.33333px;
        right: 0;
        bottom: 0;
        left: 0;
        height: 40px;
        width: 40px;
        transition: all 0.15s ease-out 0s;
        background: #cbd1d8;
        border: none;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        margin-right: 0.5rem;
        outline: none;
        position: relative;
        z-index: 1000;
      }
      .option-input:hover {
        background: #9faab7;
      }
      .option-input:checked {
        background: #40e0d0;
      }
      .option-input:checked::before {
        height: 40px;
        width: 40px;
        position: absolute;
        content: '';
        display: inline-block;
        font-size: 26.66667px;
        text-align: center;
        line-height: 40px;
      }
      .option-input:checked::after {
        -webkit-animation: click-wave 0.65s;
        -moz-animation: click-wave 0.65s;
        animation: click-wave 0.65s;
        background: #9faab7;
        content: '';
        display: block;
        position: relative;
        z-index: 100;
      }
      .option-input.radio {
        border-radius: 50%;
      }
      .option-input.radio::after {
        border-radius: 50%;
      }
      
      
    
    .radiopadding {
        padding-left: 24px;
    }

/* custom radio ends */




input[type="checkbox"]+label {
	cursor: pointer;
	position: relative;
	
	
	
}

input[type="checkbox"] {
	position: absolute;
	left: -9999px;
}

/* the checkbox
-----------------------------------------------*/
/* input[type="checkbox"]+label::after {
	content: '';
	border: 2px solid #212121;;
	border-radius: 4px;
	background-color: white;
	position: absolute;
	top: 50%;
	right: -29px;
	transform: translate(-20px, -50%);
	box-sizing: border-box;
	width: 18px;
	height: 18px;
	transition: background-color 1s;
	background-image: url(../images/checkmark.svg);
	background-position: 1px 1px;
} */

/* the checkbox -- checked
-----------------------------------------------*/
/* input[type="checkbox"]:checked+label::after {
	border: 2px solid #3DA470;
	background-color: #3DA470;
} */

/* the checkbox -- disabled
-----------------------------------------------*/



/* the focus ripple
-----------------------------------------------*/
input[type="checkbox"]+label::before {
	content: '';
	border-radius: 50%;
	background-color: rgba(0, 0, 0, .1);
	position: absolute;
	box-sizing: border-box;
	bottom: 65%;
	right: 3px;
	transform: translate(-50%, -50%) scale(0);
	width: 1.8px;
	height: 1.8px;
}

input[type="checkbox"]:focus+label::before {
	animation: ripple_check 1s ease-out;
}

input[type="checkbox"]:disabled {
	background-color: gray;
}






@keyframes ripple_check {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}


.checktextpadding {
 margin-left: 16px;
}
.checktextalign {
display: block;
}
.adecheck {
  padding-right: 15px;
}
  

@media only screen and (max-width: 768px) {

  .adecheck {
    padding-right: 15px;
    padding-left: 16px;
  }
}

th span {
  display: block;
  margin-bottom: 12px;
}
.checkbody{
    
  text-align: center !important;
  vertical-align: middle;
} 
.check_ade {  /*padding-top: 24px !important;*/
  padding-left: 16px !important;
}
.userName{
  color: #212121;
  pointer-events: none;
}
.pointerEventNone{
  pointer-events: none;
}
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3DA470;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.pre-font-family {
  font-family: "Noto Sans JP";
  font-size: 100% !important;
}
#input_file{
  z-index: 100;
  cursor: pointer;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: none;
}
#bulk-upload-dropdown2{
  width: 328px;
}
#bulk-upload-file{
  height:auto !important;
  min-height: 40px !important;
  min-width: 277px !important;
}
.lm-dropdown{
  min-width: 104px;
}
.lm-dropdown2{
  min-width: 248px;
}
.lmt-cellcolor{
  background: rgba(246,154,25,0.4) !important;
}
.addfacilitymodal{
  background-color: #F5F5F5;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  margin: 0 auto;
}
.facilitymodalelementlabel{
  min-width: 200px;
  display: inline-block;
  margin-left: 48px;
  margin-top:32px;
  margin-bottom: 0px;
}
.fmbox1{
  width:104px;
  padding: 8px;
}
.fmbox2{
  width:200px;
  padding: 8px;
  margin-right:48px;
}
.fmcheckbox{
  position: relative !important;
  left:0 !important;
}
.check_f_position{
  left: 20%;
  top: 2px;
}
.width-200{
  width: 200 !important;
}
.width-248{
  width: 248 !important;
}
@media only screen and (max-width: 769px){
  .ta{
    margin-left: .5rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1025px) {
  .ta{
    margin-left: 40px;
  }
  .year{
    margin-left: 65px;
  }
  .classification{
    margin-left: 125px;
  }
}
@media only screen and (min-width: 1025px) {
  .ta{
    margin-left: 40px;
  }
  .year{
    margin-left: 65px;
  }
  .classification{
    margin-left: 125px;
  }
}
.lr-cl1 label{
  width: 56px;
}
.lr-cl1 select{
  width: 104px;
}
.lr-cl2 label{
  width: 72px;
}
.lr-cl2 select{
  width: 248px;
}
.lr-cl3 label{
  width: 112px;
}
.lr-cl3 select, .lr-cl3 input{
  width: 248px;
}
.lr-cl1, .lr-cl2, .lr-cl3{
  padding-left:24px;
}
.lr-btn{
  margin-left:0px;
}
.lr-search-table td{
  min-width:200px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:portrait) { 
/* For portrait layouts only */ 
  .lr-cl3 label{
    width:56px;
    text-align:left;
  }
  .lr-cl3{
    margin-top:0px !important;
  }
  .lr-btn #parentSearch{
    margin-left:0px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation:landscape) { 
/* For landscape layouts only */ 
  .lr-cl2, .lr-cl3{
    padding:0px;
  }
  .lr-cl2 select, .lr-cl3 select, .lr-cl3 input{
    margin-left:0px !important;
    margin-right:0px !important;
  }
  .lr-btn #parentSearch{
    margin-left:0px;
  }
  .searchitem {
    order: 1
  }
  #year_list {
    order: 2
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .lm-checkbox{
    margin-right: 8px !important;
  }
}
