#dropdown-cat {
  width: 130px;
}
.margintop{
  margin-top: 100px;
  }
#dropdown-year {
  width: 100px;
}
.tableWrapper {
  
}
.pagination {
  float: right;
}
.svg-inline--fa.highlight path {
  fill: black !important;
}
.svg-inline--fa path {
  fill: grey !important;
}
.dropdown button,
.dropdown button:hover,
.dropdown button:focus,
.dropdown button:active {
  background-color: #fff !important;
  border-radius: 0px;
  color: #495057 !important;
  border: 1px solid #ced4da !important;
  padding: 6px 0px !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
  text-align: left;
  padding-left: 10px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent;
}
.tableWrapper .table th {
  padding: 0.75rem 0.5rem;
}
.tableWrapper .table th:focus,
.tableWrapper .table th:active,
.tableWrapper .table th:visited {
  border: 1px solid black !important;
}
.fa-sort-up {
  top: 5px;
  position: relative !important;
}
.fa-sort-down {
  top: -3px;
  position: relative !important;
}
.searchBox {
  border-radius: 0px;
}
.dropdown-toggle::after {
  right: 10px;
  top: 45%;
}
.headerPress {
  background: grey;
}
.pagination li:last-child .sr-only {
  -webkit-box-ordinal-group: 1;
  -moz-box-ordinal-group: 1;
  -ms-flex-order: 1;
  margin-right: 5px;
}
.pagination li:last-child a,
.pagination li:last-child span {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  /* optional */
  -webkit-box-align: start;
  -moz-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.pagination li:first-child .page-link span:first-child {
  margin-right: 5px;
}
.pagination li:last-child .page-link span:first-child {
  order: 2;
  margin-right: 5px;
}
.pagination li:first-child .sr-only,
.pagination li:last-child .sr-only {
  height: auto;
  width: auto;
  position: relative;
}
.pagination li:not(:first-child):not(:last-child) {
  width: 35px;
  height: 35px;
  margin: 0px 5px;
}
.pagination li:not(:first-child):not(:last-child) > span,
.pagination li:not(:first-child):not(:last-child) a:hover,
.pagination li:not(:first-child):not(:last-child) a:focus {
  border-radius: 50%;
  text-align: center !important;
}

.page-link,
.page-item.disabled .page-link {
  color: #9e9e9e;
  border: 0 none;
  background: transparent;
}
.page-item.active .page-link {
  text-align: center !important;
}
.pagination li {
  margin-top: 0px;
}
.pagination li a,
.pagination li a:hover,
.pagination li a:focus {
  text-decoration: none;
  color: #0078a3;
}
.page-item.active .page-link {
  background-color: #0078a3 !important;
  border-color: #0078a3;
}
.page-link {
  display: flex;
  justify-content: center;
}
/* .theme-lilly .lds-table:not(.flat) tr:nth-child(2n) td, .theme-lilly .lds-table:not(.flat) tr:nth-child(2n) th {
    background-color: #eee;
} */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eee;
}
.table-striped input[type="radio"] {
  display: block;
  visibility: visible;
  /* margin-left: 12px; */
  width: 14px;
  height: 14px;
}
.tablepad8 tr > th:nth-child(1){
  padding:8px 20px;
}
.radio-item {
  display: inline-block;
  position: relative;
  padding: 0 6px;
  margin: 10px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  color: #666;
  font-weight: normal;
}

.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #004c97;
  background-color: transparent;
}

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: " ";
  display: block;
  background: #004c97;
}
.btn-primary {
  text-decoration: none;
}
.form-group {
  display: flex;
}
.calenderIcon {
  display: inline-flex;
  position: relative;
  left: -20px;
  top: 10px;
  z-index: 0;
}
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input[type="radio"][name="formHorizontalRadios"] {
  display: block;
  visibility: visible;
  margin-top: 8px;
}
.form-check {
  margin-left: 10px;
}
.AddUser button {
  margin-left: 20px;
  background-color: #0078a3;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.AddUser a,
.AddUser a:hover,
.AddUser a:focus,
.AddUser a:active {
  background-color: #0078a3;
  border-color: #0078a3;
  color: #fff;
}
.checkBoxNew .form-check-inline .form-check-input {
  position: static;
  margin-top: 3px;
  width: 15px;
  height: 15px;
  margin-right: .3125rem;
  margin-left: -5px;
}
.fromSchedule .form-check{
  margin-left:30px;
}
.fromSchedule input[type="radio"]{
  margin-top:7px;
  display:block !important;
  visibility: visible !important;
   
}
input[name="Release"]:disabled{
  background-color: #e9ecef !important;
}
.svg-inline--fa {
 
  vertical-align: -5px;
}
.error{
  color:red;
  font-weight:bold;
  font-size:14px;
 
}
.modalBodyText{color:#000;}
.modalBodyText b{
  color:red
}